import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './LoginModal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faTimes } from '@fortawesome/free-solid-svg-icons';
import config from "../../config";
import {jwtDecode} from "jwt-decode";

export const isTokenExpired = (token) => {
    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Math.floor(Date.now() / 1000);
        return decodedToken.exp < currentTime;
    } catch (error) {
        return true;
    }
};

function LoginModal({ isOpen, onClose, isDarkMode }) {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [motDePasse, setMotDePasse] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [stayConnected, setStayConnected] = useState(false);

    useEffect(() => {
        const savedEmail = localStorage.getItem('email');
        const savedPassword = localStorage.getItem('motDePasse');
        if (savedEmail) setEmail(savedEmail);
        if (savedPassword) setMotDePasse(savedPassword);
    }, []);

    if (!isOpen) {
        return null;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${config.apiUrl}/api/auth/login`, {
                email: email,
                motDePasse: motDePasse
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const token = response.data.token;
            const role = response.data.role;
            const statut = response.data.statut;

            const decodedToken = jwtDecode(token);
            const clientId = decodedToken.clientId;

            if (stayConnected) {
                localStorage.setItem('token', token);
                localStorage.setItem('role', role);
                localStorage.setItem('isActive', statut === 'ACTIF');
                localStorage.setItem('clientId', clientId);
            } else {
                sessionStorage.setItem('token', token);
                sessionStorage.setItem('role', role);
                sessionStorage.setItem('isActive', statut === 'ACTIF');
                sessionStorage.setItem('clientId', clientId);
            }

            if (rememberMe) {
                localStorage.setItem('email', email);
                localStorage.setItem('motDePasse', motDePasse);
            } else {
                localStorage.removeItem('email');
                localStorage.removeItem('motDePasse');
            }

            setTimeout(() => {
                navigate('/user-dashboard');
                onClose();
            }, 300);

        } catch (error) {
            if (error.response && error.response.status === 403) {
                setErrorMessage("Compte inactif. Vous n'avez plus les droits d'accès.");
            } else if (error.response && error.response.status === 401) {
                setErrorMessage('Identifiants incorrects. Veuillez réessayer.');
            } else {
                setErrorMessage('Une erreur est survenue. Veuillez réessayer plus tard.');
            }
            console.error('Erreur lors de la connexion:', error);
        }
    };

    return (
        <div className="modal-overlay">
            <div className={`modal-content ${isDarkMode ? 'modal-dark' : 'modal-light'} transition-modal`}>
                <button className="modal-close" onClick={onClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <form onSubmit={handleSubmit} className="modal-form">
                    <h2 className="modal-title">Connexion</h2>
                    {errorMessage && <p className="error">{errorMessage}</p>}

                    <div className="input-group">
                        <label>Email</label>
                        <div className="input-wrapper">
                            <FontAwesomeIcon icon={faEnvelope} className="input-icon" />
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="modal-input"
                                placeholder="Entrez votre email"
                            />
                        </div>
                    </div>

                    <div className="input-group">
                        <label>Mot de passe</label>
                        <div className="input-wrapper">
                            <FontAwesomeIcon icon={faLock} className="input-icon" />
                            <input
                                type="password"
                                value={motDePasse}
                                onChange={(e) => setMotDePasse(e.target.value)}
                                required
                                className="modal-input"
                                placeholder="Entrez votre mot de passe"
                            />
                        </div>
                    </div>

                    <div className="input-group checkbox-group">
                        <div>
                            <input
                                type="checkbox"
                                id="rememberMe"
                                className="checkbox-input"
                                checked={rememberMe}
                                onChange={(e) => setRememberMe(e.target.checked)}
                            />
                            <label htmlFor="rememberMe" className="checkbox-label">
                                Enregistrer mes informations
                            </label>
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                id="stayConnected"
                                className="checkbox-input"
                                checked={stayConnected}
                                onChange={(e) => setStayConnected(e.target.checked)}
                            />
                            <label htmlFor="stayConnected" className="checkbox-label">
                                Restez connecté
                            </label>
                        </div>
                    </div>

                    <a href="/forgot-password" className="forgot-password-link">
                        Mot de passe oublié ?
                    </a>

                    <button type="submit" className="modal-btn">Se connecter</button>
                </form>
            </div>
        </div>
    );
}

export default LoginModal;
