import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Paper, Stack, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import axios from 'axios';
import { format } from 'date-fns'; // Utilisez date-fns pour formatter les dates
import config from "../../config";

const FormTicket = ({ title, onClose, onSuccess, ticketData }) => {
    const [titre, setTitre] = useState('');
    const [description, setDescription] = useState('');
    const [statut, setStatut] = useState('en cours');
    const [categorie, setCategorie] = useState('INCIDENT'); // Par défaut "Incident"
    const [projetId, setProjetId] = useState(''); // Optionnel pour les tickets de type Projet
    const [errorMessage, setErrorMessage] = useState(null);
    const [dateOuverture, setDateOuverture] = useState('');
    const [dateFermeture, setDateFermeture] = useState('');

    useEffect(() => {
        if (ticketData) {
            setTitre(ticketData.titre || '');
            setDescription(ticketData.description || '');
            setCategorie(ticketData.categorie || 'INCIDENT');
            setProjetId(ticketData.projet ? ticketData.projet.id : '');
            setDateOuverture(ticketData.dateOuverture || '');
            setDateFermeture(ticketData.dateFermeture || '');
        }
    }, [ticketData]);

    const formatDateForBackend = (date) => {
        if (!date || isNaN(new Date(date).getTime())) {
            return null;
        }
        return format(new Date(date), 'dd-MM-yy HH:mm:ss');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formattedDateOuverture = formatDateForBackend(dateOuverture);
        const formattedDateFermeture = formatDateForBackend(dateFermeture);

        const ticketPayload = {
            titre,
            description,
            statut,
            categorie,
            dateOuverture: formattedDateOuverture,
            dateFermeture: formattedDateFermeture,
            projet: categorie === 'PROJET' ? { id: projetId } : null,
        };

        try {
            const token = sessionStorage.getItem('token');
            console.log("Token récupéré dans sessionStorage pour l'appel API :", token);

            if (!token) {
                setErrorMessage("Le token JWT est manquant.");
                return;
            }

            let response;
            if (ticketData) {
                response = await axios.put(`${config.apiUrl}/api/tickets/${ticketData.id}`, ticketPayload, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            } else {
                response = await axios.post(`${config.apiUrl}/api/tickets`, ticketPayload, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            }

            if (response.status === 200 || response.status === 201) {
                onSuccess(response.data);
                onClose();
            }
        } catch (error) {
            console.error('Erreur lors de la soumission du ticket', error);
            setErrorMessage('Erreur lors de la soumission du ticket');
        }
    };


    return (
        <Paper elevation={3} sx={{ padding: 4, maxWidth: 600, margin: 'auto', mt: 5, backgroundColor: '#f7f8fc' }}>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                    {ticketData ? 'Modifier le Ticket' : title}
                </Typography>

                {errorMessage && (
                    <Typography variant="body1" color="error" gutterBottom>
                        {errorMessage}
                    </Typography>
                )}

                <FormControl fullWidth margin="normal" variant="outlined">
                    <InputLabel id="categorie-label">Catégorie</InputLabel>
                    <Select
                        labelId="categorie-label"
                        value={categorie}
                        onChange={(e) => setCategorie(e.target.value)}
                        label="Catégorie"
                        sx={{
                            backgroundColor: '#fff',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#d3d3d3',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#1976d2',
                            },
                        }}
                    >
                        <MenuItem value="INCIDENT">Incident</MenuItem>
                        <MenuItem value="PROJET">Projet</MenuItem>
                        <MenuItem value="MAINTENANCE">Maintenance</MenuItem>
                        <MenuItem value="AUTRE">Autre</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    label="Nom du Ticket"
                    value={titre}
                    onChange={(e) => setTitre(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    variant="outlined"
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: '8px',
                    }}
                />
                <TextField
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    multiline
                    rows={4}
                    variant="outlined"
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: '8px',
                    }}
                />

                {categorie === 'PROJET' && (
                    <TextField
                        label="ID du Projet associé"
                        value={projetId}
                        onChange={(e) => setProjetId(e.target.value)}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        sx={{
                            backgroundColor: '#fff',
                            borderRadius: '8px',
                        }}
                    />
                )}

                <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 3 }}>
                    <Button onClick={onClose} variant="outlined" color="secondary" sx={{ borderRadius: '20px' }}>
                        Annuler
                    </Button>
                    <Button type="submit" variant="contained" color="primary" sx={{ borderRadius: '20px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
                        {ticketData ? 'Modifier' : 'Créer'}
                    </Button>
                </Stack>
            </Box>
        </Paper>
    );
};

export default FormTicket;
