import React, {useEffect} from 'react';
import {
    Drawer,
    Box,
    Typography,
    IconButton,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    List,
    ListItem,
    ListItemText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

const SidebarDetails = ({
                            isOpen,
                            onClose,
                            editedItem,
                            role,
                            type,
                            handleInputChange,
                            handleSaveChanges,
                            handleReportSubmit
                        }) => {
    useEffect(() => {
        console.log("Données reçues par SidebarDetails :", {
            editedItem,
            role,
            type,
        });
    }, [editedItem, role, type]); // Suivi des modifications

    const isEditable =
        role === 'ROLE_ADMIN' ||
        (role === 'ROLE_CLIENT' && editedItem?.statut?.toLowerCase() === 'en attente');

    const isReportable = role === 'ROLE_ADMIN' && editedItem?.statut === 'EN_COURS';

    return (
        <Drawer
            anchor="right"
            open={isOpen}
            onClose={onClose}
            PaperProps={{ sx: { width: 800, padding: 2 } }}
        >
            <Box>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">Détails {type}</Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                {editedItem ? (
                    <Box mt={2}>
                        {/* Champ titre */}
                        <TextField
                            label="Titre"
                            name="titre"
                            value={editedItem.titre || editedItem.nom || ''}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            disabled={!isEditable}
                        />

                        {/* Champ description */}
                        <TextField
                            label="Description"
                            name="description"
                            value={editedItem.description || ''}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            multiline
                            rows={4}
                            InputLabelProps={{ shrink: true }}
                            disabled={!isEditable}
                        />

                        {/* Sélecteur de statut */}
                        <FormControl fullWidth margin="normal" disabled={role !== 'ROLE_ADMIN'}>
                            <InputLabel>Statut</InputLabel>
                            <Select
                                label="Statut"
                                name="statut"
                                value={editedItem.statut || ''}
                                onChange={handleInputChange}
                            >
                                <MenuItem value="EN_ATTENTE">En attente</MenuItem>
                                <MenuItem value="PRIS_EN_COMPTE">Pris en compte</MenuItem>
                                <MenuItem value="EN_COURS">En cours</MenuItem>
                                <MenuItem value="TRAITE">Traité</MenuItem>
                                <MenuItem value="CLOTURE">Clôturé</MenuItem>
                                <MenuItem value="SUPPRIME">Supprimé</MenuItem>
                            </Select>
                        </FormControl>

                        {/* Bouton de sauvegarde */}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleSaveChanges(type)}
                            fullWidth
                            sx={{ mt: 2 }}
                            disabled={!isEditable}
                        >
                            Sauvegarder les modifications
                        </Button>

                        {/* Section soumission de rapport */}
                        {isReportable && (
                            <>
                                <TextField
                                    label="Rapport de résolution"
                                    name="report"
                                    value={editedItem.report || ''}
                                    onChange={(e) =>
                                        handleInputChange({
                                            target: { name: 'report', value: e.target.value }
                                        })
                                    }
                                    fullWidth
                                    margin="normal"
                                    multiline
                                    rows={4}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleReportSubmit(editedItem.report, type)}
                                    fullWidth
                                    sx={{ mt: 2 }}
                                    disabled={!editedItem.report}
                                >
                                    Soumettre le rapport
                                </Button>
                            </>
                        )}

                        {/* Historique des rapports */}
                        <Box mt={4}>
                            <Typography variant="h6">Historique des Rapports</Typography>
                            {Array.isArray(editedItem.historiqueRapports) &&
                            editedItem.historiqueRapports.length > 0 ? (
                                <List>
                                    {editedItem.historiqueRapports.map((rapport, index) => (
                                        <ListItem key={index} divider>
                                            <ListItemText
                                                primary={`Rapport #${index + 1} - Auteur : ${
                                                    rapport.auteurNom || 'Auteur inconnu'
                                                } - Date : ${
                                                    rapport.date
                                                        ? new Date(rapport.date).toLocaleString()
                                                        : 'Date non disponible'
                                                }`}
                                                secondary={rapport.rapport || 'Pas de rapport'}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            ) : (
                                <Typography variant="body2">Aucun rapport précédent.</Typography>
                            )}
                        </Box>
                    </Box>
                ) : (
                    <Typography variant="body1">Aucun {type} sélectionné.</Typography>
                )}
            </Box>
        </Drawer>
    );
};

SidebarDetails.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    editedItem: PropTypes.object,
    role: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    handleSaveChanges: PropTypes.func.isRequired,
    handleReportSubmit: PropTypes.func.isRequired
};

export default SidebarDetails;
