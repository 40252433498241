import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, MenuItem, Paper, Stack, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, Alert } from '@mui/material';
import axios from 'axios';
import config from "../../config";

const FormClient = ({ title, onClose, onSuccess }) => {
    const [nom, setNom] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('ROLE_CLIENT');
    const [societeId, setSocieteId] = useState('');
    const [societes, setSocietes] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        const loadSocietes = async () => {
            try {
                const token = sessionStorage.getItem('token');
                const response = await axios.get(`${config.apiUrl}/api/societes`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setSocietes(Array.isArray(response.data) ? response.data : []);
            } catch (error) {
                console.error('Erreur lors du chargement des sociétés', error);
                setSocietes([]);
            }
        };

        loadSocietes();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setOpenConfirm(true);
    };

    const handleConfirmSubmit = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const response = await axios.post(`${config.apiUrl}/api/client/enregistrer`, {
                email,
                role,
                nom,
                societe: societeId ? { id: societeId } : null,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status >= 200 && response.status < 300) {
                setSnackbarMessage('Client créé avec succès.');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                setTimeout(() => {
                    onClose(); // Appeler onClose après un délai
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                }, 1000); // Attendre un peu pour que le snackbar s'affiche
            }
        } catch (error) {
            console.error('Erreur lors de la création du client', error);
            setErrorMessage('Erreur lors de la création du client');
            setSnackbarMessage('Erreur lors de la création du client.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
        setOpenConfirm(false);
    };

    const handleCancel = () => {
        setOpenConfirm(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Paper elevation={3} sx={{ padding: 3, maxWidth: 500, margin: 'auto', mt: 5 }}>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
                <Typography variant="h6" gutterBottom>
                    {title || 'Créer un Client'}
                </Typography>
                {errorMessage && (
                    <Typography variant="body1" color="error" gutterBottom>
                        {errorMessage}
                    </Typography>
                )}
                <TextField
                    label="Nom"
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    variant="outlined"
                />
                <TextField
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    variant="outlined"
                />
                <TextField
                    select
                    label="Rôle"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    variant="outlined"
                >
                    <MenuItem value="ROLE_CLIENT">Client</MenuItem>
                    <MenuItem value="ROLE_ADMIN">Admin</MenuItem>
                </TextField>

                <TextField
                    select
                    label="Société"
                    value={societeId || ''}
                    onChange={(e) => setSocieteId(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    helperText="Sélectionner une société ou laisser vide"
                >
                    <MenuItem value="">Aucune société</MenuItem>
                    {Array.isArray(societes) && societes.length > 0 ? (
                        societes.map((societe) => (
                            <MenuItem key={societe.id} value={societe.id}>
                                {societe.nom}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem disabled>Aucune société disponible</MenuItem>
                    )}
                </TextField>

                <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 3 }}>
                    <Button onClick={onClose} variant="outlined" color="secondary">
                        Annuler
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                        Créer
                    </Button>
                </Stack>
            </Box>

            {/* Dialog de Confirmation */}
            <Dialog
                open={openConfirm}
                onClose={handleCancel}
            >
                <DialogTitle>Confirmer la création du client</DialogTitle>
                <DialogContent>
                    <Typography>Êtes-vous sûr de vouloir créer ce client avec les informations fournies ?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="secondary">
                        Annuler
                    </Button>
                    <Button onClick={handleConfirmSubmit} color="primary">
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar pour le feedback utilisateur */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Paper>
    );
};

export default FormClient;
