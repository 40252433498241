import { Box, Collapse, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography, Badge } from "@mui/material";
import {
    FaProjectDiagram,
    FaTasks,
    FaBug,
    FaUserFriends,
    FaBuilding,
    FaChevronLeft,
    FaChevronRight,
    FaPlusCircle,
    FaChevronUp, FaChevronDown, FaClipboardList, FaUsers
} from 'react-icons/fa';
import logoDark from "../../assets/logo-dark.png";
import React from "react";
import PropTypes from "prop-types";

const iconStyle = {
    color: '#FFA500', // Couleur lumineuse uniforme
    fontSize: '1.5rem', // Taille uniforme
};

const hoverStyle = {
    backgroundColor: '#3B82F6',
    transition: 'background-color 0.3s ease',
};

const Sidebar = ({
                     isOpen,
                     handleDrawerToggle,
                     openProjets,
                     handleProjetsClick,
                     openTaches,
                     handleTachesClick,
                     openTickets,
                     handleTicketsClick,
                     openAdmin,
                     handleLogsClick,
                     role,
                     handleCreateClick,
                     setActiveData,
                     openClients,
                     handleClientsClick,
                     openSocietes,
                     handleSocietesClick,
                     maintenanceProjets,
                     maintenanceTaches,
                     maintenanceTickets,
                     activeSection // Ajout de cette prop pour mettre en évidence la section active
                 }) => {
    return (
        <Drawer
            variant="permanent"
            open={isOpen}
            sx={{
                width: isOpen ? 240 : 60,
                transition: 'all 0.3s ease',
                '& .MuiDrawer-paper': {
                    width: isOpen ? 240 : 60,
                    transition: 'all 0.3s ease',
                    backgroundColor: '#1E1E2F',
                    color: '#FFFFFF',
                    overflowX: 'hidden',
                    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.3)',
                    borderRight: '1px solid rgba(255, 255, 255, 0.2)',
                },
            }}
        >
            <IconButton
                onClick={handleDrawerToggle}
                sx={{
                    alignSelf: isOpen ? 'flex-end' : 'center',
                    m: 1,
                    color: '#FFFFFF',
                    transition: 'transform 0.3s ease',
                }}
            >
                {isOpen ? <FaChevronLeft /> : <FaChevronRight />}
            </IconButton>


            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    my: 3,
                    width: '100%',
                }}
            >
                {isOpen && (
                    <img
                        alt="Logo"
                        src={logoDark}
                        style={{ width: '70%', height: 'auto' }}
                    />
                )}
            </Box>

            <List>
                {/* Section Projets */}
                <ListItem
                    button
                    onClick={() => {
                        if (role !== 'ROLE_CLIENT' || !maintenanceProjets) {
                            handleProjetsClick();
                        } else {
                            alert("La section Projets est actuellement en maintenance.");
                        }
                    }}
                    className={activeSection === 'projets' ? 'active-section' : ''}
                    sx={{
                        cursor: role === 'ROLE_CLIENT' && maintenanceProjets ? 'not-allowed' : 'pointer',
                        backgroundColor: activeSection === 'projets' ? '#203a43' : 'inherit',
                        '&:hover': {
                            backgroundColor: role === 'ROLE_CLIENT' && maintenanceProjets ? 'inherit' : '#3B82F6',
                            transition: 'background-color 0.3s ease',
                        },
                    }}
                >
                    <ListItemIcon>
                        <FaProjectDiagram style={iconStyle} />
                    </ListItemIcon>
                    {isOpen && (
                        <ListItemText
                            primary={
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    sx={{ color: '#fff', textTransform: 'uppercase', fontSize: 14 }}
                                >
                                    Projets
                                </Typography>
                            }
                        />
                    )}
                    {isOpen &&
                        (openProjets ? <FaChevronUp style={iconStyle} /> : <FaChevronDown style={iconStyle} />)}
                </ListItem>

                <Collapse in={openProjets} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem
                        button
                        onClick={() => handleCreateClick('projets')}
                        sx={{
                            pl: 4,
                            cursor: 'pointer',
                            '&:hover': {
                                backgroundColor: '#203a43',
                                transition: 'background-color 0.3s ease',
                            },
                        }}
                    >
                        <ListItemIcon>
                            <FaPlusCircle style={iconStyle} />
                        </ListItemIcon>
                        {isOpen && <ListItemText primary="Créer Projet" />}
                    </ListItem>
                </List>
            </Collapse>

                {/* Section Tâches */}
                <ListItem
                    button
                    onClick={() => {
                        if (role !== 'ROLE_CLIENT' || !maintenanceTaches) {
                            handleTachesClick();
                        } else {
                            alert("La section Tâches est actuellement en maintenance.");
                        }
                    }}
                    className={activeSection === 'taches' ? 'active-section' : ''}
                    sx={{
                        cursor: role === 'ROLE_CLIENT' && maintenanceTaches ? 'not-allowed' : 'pointer',
                        backgroundColor: activeSection === 'taches' ? '#203a43' : 'inherit',
                        '&:hover': {
                            backgroundColor: role === 'ROLE_CLIENT' && maintenanceTaches ? 'inherit' : '#3B82F6',
                            transition: 'background-color 0.3s ease',
                        },
                    }}
                >
                    <ListItemIcon>
                        <FaClipboardList style={iconStyle} />
                    </ListItemIcon>
                    {isOpen && (
                        <ListItemText
                            primary={
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    sx={{ color: '#fff', textTransform: 'uppercase', fontSize: 14 }}
                                >
                                    Tâches
                                </Typography>
                            }
                        />
                    )}
                    {isOpen &&
                        (openTaches ? <FaChevronUp style={iconStyle} /> : <FaChevronDown style={iconStyle} />)}
                </ListItem>

                <Collapse in={openTaches} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem
                            button
                            onClick={() => handleCreateClick('taches')}
                            sx={{
                                pl: 4,
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: '#203a43',
                                    transition: 'background-color 0.3s ease',
                                },
                            }}
                        >
                            <ListItemIcon>
                                <FaPlusCircle style={iconStyle} />
                            </ListItemIcon>
                            {isOpen && <ListItemText primary="Créer Tâche" />}
                        </ListItem>
                    </List>
                </Collapse>

                {/* Section Tickets */}
                <ListItem
                    button
                    onClick={() => {
                        if (role !== 'ROLE_CLIENT' || !maintenanceTickets) {
                            handleTicketsClick();
                        } else {
                            alert("La section Tickets est actuellement en maintenance.");
                        }
                    }}
                    className={activeSection === 'tickets' ? 'active-section' : ''}
                    sx={{
                        cursor: role === 'ROLE_CLIENT' && maintenanceTickets ? 'not-allowed' : 'pointer',
                        backgroundColor: activeSection === 'tickets' ? '#3B82F6' : 'inherit',
                        '&:hover': hoverStyle,
                    }}
                >
                    <ListItemIcon>
                        <FaBug style={iconStyle} />
                    </ListItemIcon>
                    {isOpen && (
                        <ListItemText
                            primary={
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    sx={{ color: '#fff', textTransform: 'uppercase', fontSize: 14 }}
                                >
                                    Tickets
                                </Typography>
                            }
                        />
                    )}
                    {isOpen &&
                        (openTickets ? <FaChevronUp style={iconStyle} /> : <FaChevronDown style={iconStyle} />)}
                </ListItem>

                <Collapse in={openTickets} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem
                            button
                            onClick={() => handleCreateClick('tickets')}
                            sx={{
                                pl: 4,
                                cursor: 'pointer',
                                '&:hover': hoverStyle,
                            }}
                        >
                            <ListItemIcon>
                                <FaPlusCircle style={iconStyle} />
                            </ListItemIcon>
                            {isOpen && <ListItemText primary="Créer Ticket" />}
                        </ListItem>
                    </List>
                </Collapse>

                {/* Section Logs */}
                {role === 'ROLE_ADMIN' && (
                    <ListItem
                        button
                        onClick={handleLogsClick}
                        className={activeSection === 'logs' ? 'active-section' : ''}
                        sx={{
                            cursor: 'pointer',
                            backgroundColor: activeSection === 'logs' ? '#3B82F6' : 'inherit',
                            '&:hover': hoverStyle,
                        }}
                    >
                        <ListItemIcon>
                            <FaClipboardList style={iconStyle} />
                        </ListItemIcon>
                        {isOpen && (
                            <ListItemText
                                primary={
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        sx={{ color: '#fff', textTransform: 'uppercase', fontSize: 14 }}
                                    >
                                        Logs
                                    </Typography>
                                }
                            />
                        )}
                        {isOpen &&
                            (openAdmin ? <FaChevronUp style={iconStyle} /> : <FaChevronDown style={iconStyle} />)}
                    </ListItem>
                )}

                <Collapse in={openAdmin} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem
                            button
                            onClick={() => setActiveData('logs')}
                            sx={{
                                pl: 4,
                                cursor: 'pointer',
                                '&:hover': hoverStyle,
                            }}
                        >
                            <ListItemIcon>
                                <FaPlusCircle style={iconStyle} />
                            </ListItemIcon>
                            {isOpen && <ListItemText primary="Login/Logout" />}
                        </ListItem>
                    </List>
                </Collapse>


                {/* Section Clients */}
                {role === 'ROLE_ADMIN' && (
                    <>
                        <ListItem
                            button
                            onClick={handleClientsClick}
                            className={activeSection === 'clients' ? 'active-section' : ''}
                            sx={{
                                cursor: 'pointer',
                                backgroundColor: activeSection === 'clients' ? '#203a43' : 'inherit',
                                '&:hover': hoverStyle,
                            }}
                        >
                            <ListItemIcon>
                                <FaUsers style={iconStyle} />
                            </ListItemIcon>
                            {isOpen && (
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="h6"
                                            fontWeight="bold"
                                            sx={{ color: '#fff', textTransform: 'uppercase', fontSize: 14 }}
                                        >
                                            Clients
                                        </Typography>
                                    }
                                />
                            )}
                            {isOpen &&
                                (openClients ? <FaChevronUp style={iconStyle} /> : <FaChevronDown style={iconStyle} />)}
                        </ListItem>

                        <Collapse in={openClients} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {/* Liste des clients */}
                                <ListItem
                                    button
                                    onClick={() => setActiveData('clients')}
                                    sx={{
                                        pl: 4,
                                        cursor: 'pointer',
                                        '&:hover': hoverStyle,
                                    }}
                                >
                                    <ListItemIcon>
                                        <FaPlusCircle style={iconStyle} />
                                    </ListItemIcon>
                                    {isOpen && <ListItemText primary="Liste des Clients" />}
                                </ListItem>
                                {/* Ajouter client */}
                                <ListItem
                                    button
                                    onClick={() => handleCreateClick('creer-client')}
                                    sx={{
                                        pl: 4,
                                        cursor: 'pointer',
                                        '&:hover': hoverStyle,
                                    }}
                                >
                                    <ListItemIcon>
                                        <FaPlusCircle style={iconStyle} />
                                    </ListItemIcon>
                                    {isOpen && <ListItemText primary="Ajouter Client" />}
                                </ListItem>
                            </List>
                        </Collapse>
                    </>
                )}

                {/* Section Sociétés */}
                {role === 'ROLE_ADMIN' && (
                    <>
                        <ListItem
                            button
                            onClick={handleSocietesClick}
                            className={activeSection === 'societes' ? 'active-section' : ''}
                            sx={{
                                cursor: 'pointer',
                                backgroundColor: activeSection === 'societes' ? '#203a43' : 'inherit',
                                '&:hover': hoverStyle,
                            }}
                        >
                            <ListItemIcon>
                                <FaBuilding style={iconStyle} />
                            </ListItemIcon>
                            {isOpen && (
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="h6"
                                            fontWeight="bold"
                                            sx={{ color: '#fff', textTransform: 'uppercase', fontSize: 14 }}
                                        >
                                            Sociétés
                                        </Typography>
                                    }
                                />
                            )}
                            {isOpen &&
                                (openSocietes ? <FaChevronUp style={iconStyle} /> : <FaChevronDown style={iconStyle} />)}
                        </ListItem>

                        <Collapse in={openSocietes} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {/* Liste des sociétés */}
                                <ListItem
                                    button
                                    onClick={() => setActiveData('societes')}
                                    sx={{
                                        pl: 4,
                                        cursor: 'pointer',
                                        '&:hover': hoverStyle,
                                    }}
                                >
                                    <ListItemIcon>
                                        <FaPlusCircle style={iconStyle} />
                                    </ListItemIcon>
                                    {isOpen && <ListItemText primary="Liste des Sociétés" />}
                                </ListItem>
                                {/* Ajouter société */}
                                <ListItem
                                    button
                                    onClick={() => handleCreateClick('creer-societe')}
                                    sx={{
                                        pl: 4,
                                        cursor: 'pointer',
                                        '&:hover': hoverStyle,
                                    }}
                                >
                                    <ListItemIcon>
                                        <FaPlusCircle style={iconStyle} />
                                    </ListItemIcon>
                                    {isOpen && <ListItemText primary="Ajouter Société" />}
                                </ListItem>
                            </List>
                        </Collapse>
                    </>
                )}
            </List>
        </Drawer>
    );
};

// PropTypes validation
Sidebar.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleDrawerToggle: PropTypes.func.isRequired,
    openProjets: PropTypes.bool.isRequired,
    handleProjetsClick: PropTypes.func.isRequired,
    openTaches: PropTypes.bool.isRequired,
    handleTachesClick: PropTypes.func.isRequired,
    openTickets: PropTypes.bool.isRequired,
    handleTicketsClick: PropTypes.func.isRequired,
    openAdmin: PropTypes.bool,
    handleLogsClick: PropTypes.func,
    role: PropTypes.string,
    handleCreateClick: PropTypes.func.isRequired,
    setActiveData: PropTypes.func.isRequired,
    openClients: PropTypes.bool,
    handleClientsClick: PropTypes.func,
    openSocietes: PropTypes.bool,
    handleSocietesClick: PropTypes.func,
    maintenanceProjets: PropTypes.bool,
    maintenanceTaches: PropTypes.bool,
    maintenanceTickets: PropTypes.bool,
    activeSection: PropTypes.string.isRequired // Ajout de la prop activeSection
};

export default Sidebar;
