// src/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

function PrivateRoute({ children, allowedRole }) {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');

    if (!token || role !== allowedRole) {
        // Si l'utilisateur n'est pas authentifié ou n'a pas le bon rôle, rediriger vers la page d'accueil
        return <Navigate to="/" />;
    }

    // Si l'utilisateur est authentifié et possède le bon rôle, afficher les enfants (composants protégés)
    return children;
}

export default PrivateRoute;
