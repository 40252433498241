import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Paper, Stack } from '@mui/material';
import axios from 'axios';
import config from "../../config";

const FormSociete = ({ title, onClose, onSuccess }) => {
    const [nom, setNom] = useState('');
    const [adresse, setAdresse] = useState('');
    const [codePostal, setCodePostal] = useState('');
    const [ville, setVille] = useState('');
    const [numeroSiret, setNumeroSiret] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);


    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Formulaire soumis');

        try {
            const token = sessionStorage.getItem('token');
            console.log('Token récupéré:', token);
            console.log('Envoi des données:', { nom, adresse, codePostal, ville, numeroSiret });

            const response = await axios.post(`${config.apiUrl}/api/societes`, {
                nom,
                adresse,
                codePostal,
                ville,
                numeroSiret,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            });

            console.log('Réponse de l\'API:', response);

            if (response.status >= 200 && response.status < 300) {
                console.log('Société créée avec succès:', response.data);
                onSuccess(response.data);  // Exécute onSuccess après la création réussie
                onClose();  // Ferme le formulaire
            } else {
                console.log('Statut inattendu:', response.status);
            }
        } catch (error) {
            if (error.response) {
                console.error('Réponse d\'erreur du serveur:', error.response.data);
                setErrorMessage(`Erreur: ${error.response.data.message || 'Erreur lors de la création de la société'}`);
            } else {
                console.error('Erreur réseau ou autre:', error.message);
                setErrorMessage('Erreur réseau ou autre problème.');
            }
        }

    };


    return (
        <Paper elevation={3} sx={{ padding: 3, maxWidth: 500, margin: 'auto', mt: 5 }}>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
                <Typography variant="h6" gutterBottom>
                    {title || 'Créer une Société'}
                </Typography>
                {errorMessage && (
                    <Typography variant="body1" color="error" gutterBottom>
                        {errorMessage}
                    </Typography>
                )}

                <TextField
                    label="Nom de la Société"
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    variant="outlined"
                />
                <TextField
                    label="Adresse"
                    value={adresse}
                    onChange={(e) => setAdresse(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    variant="outlined"
                />
                <TextField
                    label="Code Postal"
                    value={codePostal}
                    onChange={(e) => setCodePostal(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    variant="outlined"
                />
                <TextField
                    label="Ville"
                    value={ville}
                    onChange={(e) => setVille(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    variant="outlined"
                />
                <TextField
                    label="N° SIRET"
                    value={numeroSiret}
                    onChange={(e) => setNumeroSiret(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    variant="outlined"
                />

                <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 3 }}>
                    <Button onClick={() => {
                        console.log('Annulation du formulaire');
                        onClose();
                    }} variant="outlined" color="secondary">
                        Annuler
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                        Créer
                    </Button>
                </Stack>
            </Box>
        </Paper>
    );
};

export default FormSociete;
