import React, { useState, useEffect } from 'react';

const CookieConsent = () => {
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        const cookieConsent = localStorage.getItem('cookieConsent');
        if (!cookieConsent) {
            setShowBanner(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'accepted');
        setShowBanner(false);
    };

    const handleDecline = () => {
        localStorage.setItem('cookieConsent', 'declined');
        setShowBanner(false);
    };

    if (!showBanner) return null;

    return (
        <div className="fixed bottom-4 left-4 right-4 bg-white border border-gray-300 shadow-lg rounded-lg p-4 z-50 transition-transform transform translate-y-0 ease-out duration-500">
            <div className="flex justify-between items-center">
                <p className="text-sm text-gray-700">
                    Nous utilisons des cookies pour améliorer votre expérience. Consultez notre <a href="/privacy" className="underline text-blue-500">Politique de Confidentialité</a>.
                </p>
                <div className="flex space-x-4">
                    <button
                        onClick={handleAccept}
                        className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition-colors"
                    >
                        Accepter
                    </button>
                    <button
                        onClick={handleDecline}
                        className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition-colors"
                    >
                        Refuser
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CookieConsent;
