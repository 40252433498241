// src/components/Head.js
import React from 'react';
import { Helmet } from 'react-helmet';

const Head = ({ title, description }) => (
    <Helmet>
            <title>{title}</title>
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="description" content={description} />
            <meta name="keywords" content="infogérance, sécurité informatique, support technique, Lou-Ann Technologies, Lille, Douai, Hauts-de-France" />
            <meta name="author" content="Lou-Ann Technologies" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content="/assets/logo-light.png" />
            <meta property="og:url" content="https://www.lou-ann.fr" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content="/assets/logo-light.png" />
            <meta name="theme-color" content="#ffffff" />
            <meta http-equiv="content-language" content="fr" />
    </Helmet>
);


export default Head;
