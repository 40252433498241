// components/ActionButtons.js
import React from 'react';
import { IconButton } from '@mui/material';
import { Visibility, HourglassEmpty, Edit, CheckCircle, Lock, Delete } from '@mui/icons-material';
import PropTypes from 'prop-types';

const ActionButtons = ({ params, role, handleActions }) => {
    const isDeleted = params.row.statut === 'SUPPRIME';
    const isCloture = params.row.statut === 'CLOTURE';
    const isTraite = params.row.statut === 'TRAITE';
    const isClientOwner = params.row.clientId === parseInt(localStorage.getItem('clientId') || sessionStorage.getItem('clientId'), 10);

    return (
        <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
            <IconButton
                color="primary"
                onClick={() => handleActions.view(params.row)}
                disabled={isDeleted}
                title="Voir les détails"
            >
                <Visibility />
            </IconButton>
            {role === 'ROLE_ADMIN' && (
                <>
                    <IconButton
                        onClick={() => handleActions.changeStatus(params.row.id, 'PRIS_EN_COMPTE')}
                        disabled={params.row.statut !== 'EN_ATTENTE'}
                        title="Prendre en compte"
                    >
                        <HourglassEmpty />
                    </IconButton>
                    <IconButton
                        onClick={() => handleActions.changeStatus(params.row.id, 'EN_COURS')}
                        disabled={params.row.statut !== 'PRIS_EN_COMPTE'}
                        title="Marquer comme en cours"
                    >
                        <Edit />
                    </IconButton>
                    <IconButton
                        onClick={() => handleActions.treat(params.row)}
                        disabled={params.row.statut !== 'EN_COURS'}
                        title="Traiter"
                    >
                        <CheckCircle />
                    </IconButton>
                </>
            )}
            {role === 'ROLE_CLIENT' && isClientOwner && isTraite && (
                <IconButton
                    color="success"
                    onClick={() => handleActions.cloture(params.row)}
                    sx={{ color: 'green' }}
                    title="Clôturer"
                >
                    <Lock />
                </IconButton>
            )}
            {role === 'ROLE_ADMIN' && (
                <IconButton
                    color="error"
                    onClick={() => handleActions.delete(params.row)}
                    disabled={isDeleted || isCloture}
                    title="Supprimer"
                >
                    <Delete />
                </IconButton>
            )}
        </div>
    );
};

// Ajoutez la validation des props ici
ActionButtons.propTypes = {
    params: PropTypes.object.isRequired,
    role: PropTypes.string.isRequired,
    handleActions: PropTypes.shape({
        view: PropTypes.func.isRequired,
        changeStatus: PropTypes.func.isRequired,
        treat: PropTypes.func.isRequired,
        cloture: PropTypes.func.isRequired,
        delete: PropTypes.func.isRequired,
    }).isRequired,
};

export default ActionButtons;
