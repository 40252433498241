import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './Home';
import LoginModal from './features/Auth/LoginModal';
import UserDashboard from './features/Dashboard/UserDashboard';  // On utilise le même UserDashboard pour tous les rôles
import { ThemeProvider } from './hooks/ThemeContext';
import PrivateRoute from "./PrivateRoute";
import SetPassword from "./features/Auth/SetPassword";
import ProfileSection from './features/Profile/ProfileSection';
import axios from "axios";
import config from "./config";
import ParentComponent from "./components/ParentComponent";

function App() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [role, setRole] = useState(null);
    const [clientData, setClientData] = useState(null);
    const [isActive, setIsActive] = useState(false);  // <-- Ajoutez ceci pour définir l'état de isActive
    const [isLoading, setIsLoading] = useState(true); // État de chargement

    useEffect(() => {
        console.log("useEffect triggered - Checking role and status from storage");

        // Ajoutez la logique pour récupérer les données du client
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        if (token) {
            axios.get(`${config.apiUrl}/api/client/profile`, {
                headers: { 'Authorization': `Bearer ${token}` },
            })
                .then(response => {
                    setClientData(response.data);
                })
                .catch(error => {
                    console.error('Erreur lors de la récupération des données du client', error);
                });
        }

        // Récupération du rôle et du statut actif depuis le stockage
        const roleFromSessionStorage = sessionStorage.getItem('role');
        const roleFromLocalStorage = localStorage.getItem('role');
        const statusFromSessionStorage = sessionStorage.getItem('isActive');
        const statusFromLocalStorage = localStorage.getItem('isActive');

        const roleFromStorage = roleFromSessionStorage || roleFromLocalStorage;
        const statusFromStorage = statusFromSessionStorage || statusFromLocalStorage;

        // Assurez-vous que `isActive` est bien un booléen
        const isActiveBoolean = statusFromStorage === 'true';

        console.log("Role from storage:", roleFromStorage);
        console.log("Status from storage:", statusFromStorage, "Parsed as:", isActiveBoolean);

        // Mise à jour de l'état du rôle et du statut
        setRole(roleFromStorage);
        setIsActive(isActiveBoolean); // <-- Mise à jour de l'état de `isActive`
        setIsLoading(false);  // Fin du chargement
    }, []);


    if (isLoading) {
        return <div>Loading...</div>; // Affiche un écran de chargement pendant que le rôle est récupéré
    }

    const handleSuccess = (updatedData) => {
        setClientData(updatedData);
    };

    /**
     * Composant PrivateRoute pour protéger les routes basées sur les rôles et statut actif
     */
    const PrivateRoute = ({ children, allowedRoles }) => {
        const userRole = localStorage.getItem('role') || sessionStorage.getItem('role');
        const userStatus = localStorage.getItem('isActive') || sessionStorage.getItem('isActive');

        // Convertir le statut en booléen
        const isActiveBoolean = userStatus === 'true';

        console.log("Rôle de l'utilisateur dans PrivateRoute :", userRole);
        console.log("Statut de l'utilisateur dans PrivateRoute :", userStatus);

        // Vérifiez si le rôle de l'utilisateur est dans la liste des rôles autorisés et si l'utilisateur est actif
        if (!userRole || !allowedRoles.includes(userRole) || !isActiveBoolean) {
            console.warn("Accès refusé : redirection vers la page d'accueil");
            return <Navigate to="/" />;  // Rediriger vers la page d'accueil si l'accès est refusé
        }

        return children;  // Si les conditions sont remplies, on rend les enfants du composant
    };


    const handleOpenModal = () => {
        console.log("Modal is being opened");
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        console.log("Modal is being closed");
        setIsModalOpen(false);
    };

    return (
        <ThemeProvider>
            <Router>
                <div className="App">
                    <Routes>
                        {/* Accueil */}
                        <Route path="/" element={<HomePage onLoginClick={handleOpenModal} />} />
                        <Route path="/profile" element={<ParentComponent />} />
                        <Route path="/set-password" element={<SetPassword />} />

                        {/* Tableau de bord utilisateur pour admin et client */}
                        <Route
                            path="/user-dashboard"
                            element={
                                <PrivateRoute allowedRoles={['ROLE_ADMIN', 'ROLE_CLIENT']}>
                                    <UserDashboard role={role} />  {/* Passer le rôle au UserDashboard */}
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/profile"
                            element={
                                <PrivateRoute allowedRoles={['ROLE_ADMIN', 'ROLE_CLIENT']}>
                                    <ProfileSection clientData={clientData} onSuccess={handleSuccess} />
                                </PrivateRoute>
                            }
                        />
                    </Routes>

                    <LoginModal isOpen={isModalOpen} onClose={handleCloseModal} isDarkMode={false} />
                </div>
            </Router>
        </ThemeProvider>
    );
}

export default App;
