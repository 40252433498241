import React, { useState, useEffect } from 'react';
import ProfileSection from '../features/Profile/ProfileSection'; // Chemin du composant
import axios from 'axios';
import config from '../config';

const ParentComponent = () => {
    const [clientData, setClientData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchClientData = async () => {
            try {
                const token = localStorage.getItem('token') || sessionStorage.getItem('token');
                const response = await axios.get(`${config.apiUrl}/api/client/profile`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setClientData(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des données client', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchClientData();
    }, []);

    if (isLoading) {
        return <div>Chargement...</div>;
    }

    return <ProfileSection clientData={clientData} onSuccess={() => console.log('Profil mis à jour')} />;
};

export default ParentComponent;
